import * as Yup from 'yup';

export const userFormSchema = () => {
    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required').trim(),
        lastName: Yup.string().required('Last name is required').trim(),
        email: Yup.string().email('Invalid email').required('Email is required').trim(),
        birthdate: Yup.date().nullable().max(new Date().getFullYear() + 1, "Invalid birthdate").min(1900, "Invalid birthdate"),
        password: Yup.string().trim()
            .min(12, 'Password must be at least 12 characters long')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                'Password must contain at least one special character'
            )
            .required('Password is required'),
        confirmPassword: Yup.string().trim()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Confirm password is required'),
        platformLanguageAlpha2: Yup.string().required('Please select your portal language').trim(),
        communicationLanguage: Yup.string().required('Please select communication language').trim(),
        userLanguages: Yup.array().min(1, "Please select at least one option")
            .required("Please select at least one option"),

    });

    return schema;
}

export const userUpdateFormSchema = () => {
    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required').trim(),
        lastName: Yup.string().required('Last name is required').trim(),
        email: Yup.string().trim().email('Invalid email').required('Email is required'),
        birthdate: Yup.date().nullable().max(new Date().getFullYear() + 1, "Invalid birthdate").min(1900, "Invalid birthdate"),
        platformLanguageAlpha2: Yup.string().required('Please select your portal language').trim(),
        communicationLanguage: Yup.string().required('Please select communication language').trim(),
        userLanguages: Yup.array().min(1, "Please select at least one option")
            .required("Please select at least one option"),
    });

    return schema;
}